<template>
  <div class="header_container" :style="{ visibility }">
    <div class="header_wrapper" :class="{'white_bg': hasWhiteBg, 'has-box-show': hasWhiteBg }">
      <div class="header_inner">
        <router-link
        :to="{ path: '/' }"
        >
          <img class="logo" :src="logo" alt="西图盟">
        </router-link>
        <ul class="navs" @mouseover="mouseoverNavs" @mouseleave="mouseleaveNavs()">
          <li data-key="solution" :selected="currentHoverNav === 'solution'">解决方案</li>
          <li data-key="product" :selected="currentHoverNav === 'product'">产品与服务</li>
          <li data-key="case" :selected="currentHoverNav === 'case'">客户案例</li>
          <li data-key="about" @click="$router.push('/about')">关于我们</li>
          <li data-key="news" @click="$router.push('/news')">行业动态</li>
        </ul>
      </div>
      <div class="header_dropdown_mask" v-show="showNavDropdown">
        <div class="header_dropdown_content" ref="headerDropdownContent" v-show="showNavDropdown" @mouseover="showNavDropdownFun()" @mouseleave="mouseleaveDropdown">
          <solution-case-nav-card v-if="currentHoverNav === 'solution'" />
          <product-service-nav-card v-else-if="currentHoverNav === 'product'" />
          <customer-case-nav-card v-else-if="currentHoverNav === 'case'" />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Vue from 'vue'
import ProductServiceNavCard from './_components/ProductServiceNavCard'
import CustomerCaseNavCard from './_components/CustomerCaseNavCard'
import SolutionCaseNavCard from './_components/SolutionCaseNavCard'

const NavCardHeight = {
  solution: '453px',
  product: '253px',
  case: '263px',
}
export default {
  name: 'navigation',
  components: {
    ProductServiceNavCard,
    CustomerCaseNavCard,
    SolutionCaseNavCard,
  },
  data () {
    return {
      logo: require('@/assets/logo@3x.svg'),
      hasWhiteBg: false,
      visible: true,
      ifSelected: false, // 是否强制激活Tab
      showNavDropdown: false,
      currentHoverNav: '',
    }
  },
  computed: {
    visibility () {
      return this.visible ? 'visible' : 'hidden'
    },
    routerList () {
      const routes = this.$router.options.routes
      return routes.splice(0, 5)
      // return routes.splice(0, 4)
    },
  },
  watch: {
    '$route.path': {
      handler (newValue, oldValue) {
        this.ifSelected = newValue === '/newsDetails'
        this.visible = true
        this.handleScroll()
      },
      immediate: true,
    },
  },
  created () {
    Vue.$gNavigation = this
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  },
  methods: {
    mouseoverNavs (e) {
      const targetDatakeyAttr = e.target.attributes['data-key']
      this.currentHoverNav = targetDatakeyAttr ? targetDatakeyAttr.value : ''
      if (['solution', 'product', 'case'].includes(this.currentHoverNav)) {
        if (!this.showNavDropdown) {
          const keyframes = `@keyframes navAnimate {
            0% {
              height: 0px;
              overflow: hidden;
            }

            100% {
              height: ${NavCardHeight[this.currentHoverNav]};
            }
          }`
          const sheet = document.styleSheets[0]
          if (sheet.cssRules[0].name === 'navAnimate') {
            sheet.deleteRule(0)
          }
          sheet.insertRule(keyframes, 0)
          this.$refs.headerDropdownContent.style.animation = 'navAnimate .5s'
        }
        this.showNavDropdownFun()
      } else {
        this.hideNavDropdownFun()
      }
    },
    mouseleaveNavs () {
      this.hideNavDropdownFun()
      setTimeout(() => {
        if (!this.showNavDropdown) {
          this.currentHoverNav = ''
        }
      }, 0)
    },
    mouseleaveDropdown () {
      this.hideNavDropdownFun()
      this.currentHoverNav = ''
    },
    showNavDropdownFun () {
      this.showNavDropdown = true
      document.documentElement.style.overflowY = 'hidden'
    },
    hideNavDropdownFun () {
      this.showNavDropdown = false
      document.documentElement.style.overflowY = 'overlay'
    },
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    handleScroll () {
      const { path } = this.$route
      // 兼容ie
      const scrollY = window.scrollY || window.pageYOffset
      // 首页时有透明背景和白色背景之分
      if (['/', '/home'].indexOf(path)) {
        if (scrollY >= 10) {
          this.hasWhiteBg = true
        } else {
          this.hasWhiteBg = false
        }
      } else {
        // this.hasWhiteBg = true
      }
    },
    isSelected (path) {
      const { path: currentPath } = this.$route
      console.log('isSelected', path, currentPath)
      // if(path.startsWith('http')){
      //   return ''
      // }
      return path && (currentPath === path || currentPath.includes(path))
    },
  },
}
</script>
<style lang="less" scoped>
@import './index';
.nav_btn_wrapper:empty{
  display:none;
}
</style>
