import Vue from 'vue'
import Router from 'vue-router'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view'),
}

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/home/index'),
    meta: {
      title: '首页',
    },
  },
  // 解决方案
  {
    path: '/solution',
    name: 'solution',
    component: RouteView,
    meta: {
      title: '解决方案',
    },
  },
  // 产品与服务
  {
    path: '/product',
    name: 'product',
    redirect: '/product/virtualFactory',
    component: RouteView,
    children: [
      {
        path: '/product/virtualFactory',
        name: 'virtualFactory',
        component: () => import(/* webpackChunkName: "product" */ '@/views/product/virtualFactory'),
        meta: {
          title: '虚拟工厂',
        },
      },
      {
        path: '/product/robotSoftware',
        name: 'robotSoftware',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/product/robotSoftware'
          ),
        meta: {
          title: '机器人软件',
        },
      },
      {
        path: '/product/AIApplication',
        name: 'AIApplication',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/product/AIApplication'
          ),
        meta: {
          title: 'AI应用',
        },
      },
      {
        path: '/product/appSystem',
        name: 'appSystem',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/views/product/appSystem'),
        meta: {
          title: '应用系统',
        },
      },
      {
        path: '/product/processControl',
        name: 'processControl',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/product/processControl'
          ),
        meta: {
          title: '过程管控',
        },
      },
      {
        path: '/product/craftTraining',
        name: 'craftTraining',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/product/craftTraining'
          ),
        meta: {
          title: '工艺培训',
        },
      },
      {
        path: '/product/gimom',
        name: 'ImomDigitalOperation',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/product/ImomDigitalOperation'
          ),
        meta: {
          title: 'Ｇ－iMOM数字化运营',
        },
      },
      {
        path: '/product/getaverse',
        name: 'MetaverseFactory',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/product/MetaverseFactory'
          ),
        meta: {
          title: '元宇宙工厂',
        },
      },
    ],
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/about/index'),
    meta: {
      title: '关于我们',
    },
  },
  // 行业动态
  {
    path: '/news',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/news/index'),
    meta: {
      title: '行业动态',
    },
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/news/newsDetails'),
  },
  {
    path: '*',
    redirect: '/home',
  },

  // 汽车行业
  {
    path: '/carIndustry',
    name: 'carIndustry',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/carIndustry'),
  },

  // 设备制造
  {
    path: '/deviceFabrication',
    name: 'deviceFabrication',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/deviceFabrication'),
  },

  // 化工行业
  {
    path: '/chemicalIndustry',
    name: 'chemicalIndustry',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/chemicalIndustry'),
  },

  // 政务服务
  {
    path: '/governmentService',
    name: 'governmentService',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/governmentService'),
  },

  // 物流行业
  {
    path: '/logisticsIndustry',
    name: 'logisticsIndustry',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/logisticsIndustry'),
  },

  // 产业集群
  {
    path: '/industrialCluster',
    name: 'industrialCluster',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/industrialCluster'),
  },

  // 家电行业
  {
    path: '/homeApplianceIndustry',
    name: 'homeApplianceIndustry',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/homeApplianceIndustry'),
  },

  // 行业通用
  {
    path: '/industryGeneral',
    name: 'industryGeneral',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/page/industryGeneral'),
  },
  {
    path: '/navigation',
    name: 'navigation',
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/programme/components/navigation'),
  },
]

export default new Router({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  },
})
